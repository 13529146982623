(function($) {

    /*/
     *
     * The code in this file enables blinking of mascots on the AGA logo. It
     * works by finding a closed-eyes variant of the logo. If one exists (no
     * 404 error or similar occurs), then every 5 seconds, that variant is
     * shown for a quarter of a second. It is assumed that the browser is
     * capable of caching the image, so that no flickering occurs.
     *
    /*/

    function blink() {
        $navOuter.css('background-image', 'url("' + srcClosed + '")');
        window.setTimeout(function() {
            $navOuter.css('background-image', 'url("' + srcNormal + '")');
        }, 250);
    }

    var $navOuter = $('#nav-outer'),
        srcNormal = $navOuter.css('background-image');

    // don't crash when there is no navigation or logo to blink
    if ($navOuter.size() === 0 || typeof(srcNormal) === 'undefined') {
        return;
    }

    var srcClosed = null;

    // find URL and closed eyes version
    // filter `url("` at start and `")` at end
    srcNormal = srcNormal.substr(5, srcNormal.length - 7);
    srcClosed = srcNormal.replace(/\.svg$/, '-closed.svg');

    // check whether this is the logo variant without mascot: don't do anything
    // if that is the case, because it does not have anything that blinks
    var name = srcNormal.split('/').pop();
    if (name === 'logo.svg') {
        return false;
    }

    var count = 0,
        img = new Image();

    // set what to do when browser has cached an image
    img.addEventListener('load', function() {
        if (++count === 1) {
            // if normal version loaded, load version with closed eyes
            img.src = srcClosed;
        } else {
            // both versions loaded, let's blink without flickering!
            window.setInterval(blink, 5000);
        }
    });
    // first load normal version in browser cache
    img.src = srcNormal;

}(jQuery));
